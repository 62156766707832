
const config: IConfig = {
  serve: 'https://rap-server.dev-01-private.7moor.com',
  keys: ['some secret hurr'],
  session: {
    key: 'koa:sess',
  },
}

export default config
